<template>
  <div class="layout-wrapper">
    <slot />
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const settings = ref(null);

onMounted(() => {
  if (window) {
    settings.value = JSON.parse(window.localStorage.getItem("settings"));

    if (
      settings.value &&
      settings.value.settingList &&
      settings.value.settingList.find((item) => item.type === "largeText") &&
      settings.value.settingList.find((item) => item.type === "largeText")
        .default
    ) {
      var root = document.querySelector(":root");
      root.style.fontSize = "24px";
    }
  }
});
</script>

<style lang="scss"></style>
